import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

function Seo({ meta, data }) {
  const { title, description, siteUrl, keywords, lang, author, openGraphImage, pageTitle } = data;

  return (
    <Helmet
      defer={false}
      htmlAttributes={{lang: lang}}
      title={`${pageTitle} | ${title}` || ''}
      link={
        [
          {
            rel: "canonical",
            href: siteUrl,
          }
        ]
      }
      meta={[
        {
          name: `description`,
          content: description || '',
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:title`,
          content: `${pageTitle} | ${title}` || '',
        },
        {
          property: `og:description`,
          content: description || '',
        },
        {
          name: `keywords`,
          content: keywords || '',
        },
        {
          property: `og:url`,
          content: siteUrl,
        },
        {
          property: `og:image`,
          content: openGraphImage
        },
        {
          property: `og:image:width`,
          content: `1200`,
        },
        {
          property: `og:image:height`,
          content: `630`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:image`,
          content: openGraphImage
        },
        {
          name: `twitter:creator`,
          content: author || '',
        },
        {
          name: `twitter:description`,
          content: description || '',
        },
        {
          name: `twitter:title`,
          content: `${pageTitle} | ${title}` || '',
        },
      ].concat(meta)}
    />
  );
}

Seo.defaultProps = {
  meta: [],
};

Seo.propTypes = {
  meta: PropTypes.arrayOf(PropTypes.object),
};

export default Seo;
