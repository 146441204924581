import React from 'react';
import PropTypes from 'prop-types';
import MainLayout from '../Layout/MainLayout';

const Logo = ({ width, negative }) => (
  <svg width={width} height={width / 4.15217391304} viewBox="0 0 191 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M39.6666 0.120777V6.7117H40.2707V9.23532H39.5324V7.46342H34.7939V9.23532H34.0556V6.7117H34.6059C34.9024 6.20118 35.1454 5.66137 35.3308 5.10089C35.5951 4.08933 35.7126 3.04505 35.6798 2.00006V0.120777H39.6666ZM36.4315 2.29538C36.4587 3.31089 36.332 4.32466 36.0557 5.30224C35.9005 5.76906 35.6981 6.21883 35.4516 6.64458H38.7001V0.899339H36.4181L36.4315 2.29538Z" fill={negative ? '#FFF' : '#000855'}/>
    <path d="M47.7072 3.7183C47.7679 4.20268 47.7252 4.69444 47.5819 5.16109C47.4385 5.62773 47.1977 6.05864 46.8754 6.42532C46.5532 6.792 46.1567 7.08611 45.7123 7.2882C45.268 7.4903 44.7858 7.59579 44.2976 7.59769C43.8286 7.59107 43.3661 7.48659 42.9398 7.29095C42.5135 7.09531 42.1327 6.8128 41.8219 6.46153C41.511 6.11025 41.277 5.69794 41.1347 5.25099C40.9923 4.80404 40.9449 4.33229 40.9954 3.86596C40.9432 3.38488 40.9916 2.89821 41.1375 2.43683C41.2834 1.97546 41.5237 1.54947 41.8431 1.18593C42.1625 0.82238 42.5539 0.529218 42.9926 0.325058C43.4314 0.120898 43.9078 0.0102022 44.3916 0C44.8611 0.00288714 45.3248 0.104274 45.7527 0.297605C46.1806 0.490937 46.5631 0.77191 46.8756 1.12236C47.1881 1.47281 47.4236 1.88494 47.5668 2.3321C47.7101 2.77926 47.7579 3.25151 47.7072 3.7183ZM42.0022 3.83911C42.0022 5.40966 42.8479 6.81912 44.3513 6.81912C45.8547 6.81912 46.7138 5.47678 46.7138 3.75857C46.7138 2.29541 45.9487 0.765138 44.3647 0.765138C42.7807 0.765138 41.9888 2.2283 41.9888 3.83911H42.0022Z" fill={negative ? '#FFF' : '#000855'} />
    <path d="M55.2374 3.7183C55.2983 4.2038 55.2553 4.6967 55.1111 5.16429C54.9669 5.63187 54.725 6.06344 54.4012 6.43034C54.0775 6.79724 53.6794 7.09107 53.2334 7.29233C52.7874 7.4936 52.3037 7.59769 51.8144 7.59769C51.3454 7.59107 50.8829 7.48659 50.4566 7.29095C50.0303 7.09531 49.6495 6.8128 49.3387 6.46153C49.0279 6.11025 48.7938 5.69794 48.6515 5.25099C48.5092 4.80404 48.4617 4.33229 48.5122 3.86596C48.4599 3.38378 48.5087 2.89599 48.6554 2.43371C48.8022 1.97143 49.0436 1.54481 49.3644 1.18106C49.6853 0.817306 50.0784 0.524413 50.5187 0.321061C50.959 0.117709 51.4369 0.008364 51.9218 0C52.3913 0.00288714 52.855 0.104274 53.2829 0.297605C53.7108 0.490937 54.0934 0.77191 54.4059 1.12236C54.7184 1.47281 54.9539 1.88494 55.0971 2.3321C55.2403 2.77926 55.2881 3.25151 55.2374 3.7183ZM49.5324 3.83911C49.5324 5.40966 50.3781 6.81912 51.8815 6.81912C53.385 6.81912 54.2306 5.36939 54.2306 3.772C54.2306 2.30884 53.4655 0.778561 51.8815 0.778561C50.2976 0.778561 49.519 2.2283 49.519 3.83911H49.5324Z" fill={negative ? '#FFF' : '#000855'} />
    <path fillRule="evenodd" clipRule="evenodd" d="M22.631 25.8019L35.6443 36.1444H28.842V45.4871H16.4258L16.2782 35.9162H10.07L22.631 25.8019ZM22.633 26.4422L11.4882 35.4162H16.7705L16.9182 44.9871H28.342V35.6444H34.2115L22.633 26.4422Z" fill={negative ? '#FFF' : '#000855'} />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.8515 1.03866H29.2815L29.4157 10.5962H35.6369L23.0625 20.7239L10.0633 10.3814H16.8515V1.03866ZM17.3515 1.53866V10.8814H11.4948L23.0606 20.0835L34.219 11.0962H28.9227L28.7885 1.53866H17.3515Z" fill={negative ? '#FFF' : '#000855'} />
    <path d="M46.0561 29.4914V17.5713H36.7134V11.2489L26.9411 23.5314L36.4986 35.3843V29.639L46.0561 29.4914Z" fill={negative ? '#FFF' : '#000855'} />
    <path d="M0 29.4914V17.5713H9.34274V11.2489L19.115 23.5314L9.55751 35.3843V29.639L0 29.4914Z" fill={negative ? '#FFF' : '#000855'} />
    <path d="M78.8095 12.5644L69.7218 26.8469C69.7218 26.8469 66.4733 31.7733 64.0571 32.4445C63.2074 32.5127 62.3568 32.3257 61.614 31.9076L60.7012 30.874L59.9361 30.6726L59.238 30.7397L56.8755 33.0217C56.8755 33.0217 56.8755 34.203 57.493 34.552C58.1105 34.901 57.842 35.1695 59.5065 35.4514C61.2267 35.7324 62.9812 35.7324 64.7014 35.4514C65.2115 35.2634 66.6075 34.5654 67.48 34.109C67.9765 33.8573 68.4117 33.4999 68.7553 33.062C70.4198 30.9142 75.6684 24.1354 77.1181 21.7997C78.89 18.9539 81.4405 15.6652 81.4405 15.6652L84.2997 11.7858C84.2997 11.7858 91.8974 10.8327 95.4948 10.8998C96.948 10.9764 98.4043 10.9764 99.8575 10.8998L100.891 8.77893L100.274 8.16145H99.6561C99.6561 8.16145 83.011 8.51046 79.5209 8.71182C74.0441 9.89308 70.8493 10.8596 70.8493 10.8596L67.7754 12.5644L67.2921 13.3966L68.6345 15.9605L69.5338 16.0276L77.5879 12.4167L79.4538 11.8395" fill={negative ? '#FFF' : '#000855'} />
    <path d="M90.662 12.0811L79.6413 29.6927C79.6413 29.6927 78.299 32.9546 78.9164 33.9883C79.5339 35.0219 82.3529 34.74 82.3529 34.74L84.9302 34.4312L89.1451 32.8204L89.8431 30.8069L85.2657 32.1492C85.0405 32.1606 84.8155 32.1211 84.6075 32.0338C84.3995 31.9464 84.2139 31.8133 84.0644 31.6444C83.9148 31.4755 83.8052 31.2751 83.7436 31.0581C83.682 30.8411 83.6701 30.613 83.7086 30.3908C83.9936 29.0341 84.4445 27.7177 85.051 26.4711C88.5948 21.3702 93.8568 12.4838 93.8568 12.4838L93.9642 12.0811H90.662Z" fill={negative ? '#FFF' : '#000855'} />
    <path d="M147.739 19.4103L140.732 31.0753C140.732 31.0753 139.39 33.5721 140.034 34.4715C140.678 35.3709 141.564 35.9212 144.329 34.4715L146.786 33.1291C146.995 33.0169 147.197 32.8913 147.39 32.7533L151.055 30.1894L150.437 33.9345C150.437 33.9345 149.954 35.9481 151.551 35.6662C153.221 35.217 154.854 34.6427 156.437 33.948C157.318 33.5854 158.141 33.0971 158.88 32.4982L159.592 31.921V30.1088L155.296 32.0553C155.296 32.0553 154.33 31.3572 155.78 29.3706C157.216 27.1054 158.49 24.7414 159.592 22.2964L157.162 20.5648C157.162 20.5648 148.222 30.2028 146.759 30.4847C145.296 30.7666 144.262 30.4176 145.309 28.9544C146.356 27.4913 151.417 20.5648 151.417 20.5648L147.739 19.4103Z" fill={negative ? '#FFF' : '#000855'} />
    <path d="M167.713 18.9405L154.813 42.794V43.6263L158.478 44.0424L171.109 20.0547L167.713 18.9405Z" fill={negative ? '#FFF' : '#000855'} />
    <path d="M173.401 15.622C174.857 14.679 175.505 13.0923 174.848 12.078C174.191 11.0637 172.478 11.006 171.022 11.9491C169.566 12.8921 168.918 14.4788 169.575 15.4931C170.232 16.5073 171.945 16.5651 173.401 15.622Z" fill={negative ? '#FFF' : '#000855'} />
    <path d="M125.818 20.954C125.061 20.5148 124.235 20.2062 123.375 20.0412C123.109 19.9891 122.836 19.9891 122.57 20.0412L120.315 20.5245C120.102 20.5689 119.895 20.6364 119.697 20.7258C117.174 21.8774 114.769 23.271 112.516 24.8871C111.329 26.2928 110.239 27.7772 109.254 29.3303L108.972 29.7464L105.925 31.6257C105.925 31.6257 104.193 32.5922 103.844 31.6257C103.495 30.6592 105.025 28.6457 105.025 28.6457L106.301 26.4845L107.388 24.0011C107.388 24.0011 107.938 22.5514 107.656 21.9608C107.436 21.6457 107.127 21.4026 106.769 21.2617C106.411 21.1209 106.019 21.0886 105.643 21.1688C104.184 21.7083 102.789 22.406 101.482 23.2494C100.931 23.7998 96.5552 26.5785 96.5552 26.5785L102.247 16.5914L99.468 15.4772L84.568 44.0424H87.897L94.6088 32.5519C94.6088 32.5519 99.4278 27.6389 100.126 27.3033C100.824 26.9677 103.951 24.3367 102.81 26.2294C101.978 27.5718 101.468 28.3504 101.468 28.3504C101.468 28.3504 98.7834 31.035 99.0788 33.8003C99.1641 34.0118 99.295 34.2021 99.462 34.3575C99.629 34.5129 99.8281 34.6297 100.045 34.6997H101.482L102.824 34.4178L107.254 32.7667C107.741 32.4998 108.309 32.4187 108.851 32.5385C109.469 32.8204 110.583 34.4849 110.583 34.4849C111.087 34.9124 111.721 35.1582 112.381 35.1829H116.408C116.408 35.1829 120.986 34.0688 121.684 33.3842C122.382 32.6996 124.248 30.6995 123.899 30.2699C123.55 29.8404 119.737 32.6324 117.187 32.6324C114.637 32.6324 114.207 32.2834 113.858 31.8002C113.641 31.4462 113.543 31.0326 113.576 30.6189L117.603 29.4377C117.603 29.4377 125.295 25.7596 125.791 24.9274C126.288 24.0951 127.523 21.9205 125.818 20.954ZM119.227 26.3234C117.885 27.1825 116.543 27.3973 116.14 26.7932C115.737 26.1892 116.516 25.0213 117.831 24.1085C119.147 23.1957 120.516 23.0347 120.919 23.6387C121.321 24.2428 120.543 25.5046 119.227 26.3503V26.3234Z" fill={negative ? '#FFF' : '#000855'} />
    <path d="M185.942 31.1424C185.392 31.1424 185.808 29.0752 186.774 27.5852C187.741 26.0952 189.888 22.9676 189.888 22.9676C190.095 22.7495 190.237 22.4785 190.298 22.1846C190.36 21.8908 190.339 21.5856 190.237 21.303C189.967 20.7628 189.529 20.3248 188.989 20.0547L188.707 19.9204C187.331 19.2784 185.822 18.9694 184.304 19.0183C182.786 19.0673 181.301 19.4729 179.968 20.2023C177.217 21.9451 174.617 23.9163 172.196 26.0952C172.196 26.0952 167.203 30.5384 169.149 33.7197C171.096 36.9011 172.948 35.9615 172.948 35.9615L175.445 35.0755C176.07 34.7786 176.652 34.3993 177.176 33.948L180.801 31.5049C180.681 32.4798 180.749 33.4686 181.002 34.4178C181.284 34.9681 182.599 35.0889 183.459 35.1158C183.915 35.1276 184.371 35.0548 184.801 34.901C186.381 34.442 187.87 33.7156 189.204 32.7533L189.754 32.0552C189.892 31.879 190.005 31.6848 190.09 31.478L190.788 29.7733C190.788 29.7733 186.506 31.1424 185.942 31.1424ZM180.398 28.6859C177.27 30.7129 174.102 31.3706 173.337 30.2162C172.572 29.0618 174.492 26.4174 177.619 24.3904C180.747 22.3635 183.915 21.7057 184.68 22.8602C185.445 24.0146 183.539 26.659 180.398 28.6859Z" fill={negative ? '#FFF' : '#000855'} />
    <path d="M138.611 25.5046C139.196 25.7665 139.841 25.8673 140.478 25.7965C141.115 25.7257 141.722 25.4859 142.235 25.1019C142.462 24.9478 142.655 24.7494 142.802 24.5187C142.95 24.288 143.049 24.0297 143.094 23.7595C143.094 21.4776 142.625 20.2292 141.846 19.8533C140.53 19.4794 139.135 19.4794 137.819 19.8533C135.224 21.023 132.713 22.3685 130.302 23.8804C130.057 24.0261 129.832 24.202 129.631 24.4039C128.664 25.3704 125.872 28.4309 125.698 31.0082C125.698 31.5049 124.932 34.2567 126.637 34.7936C127.928 35.26 129.292 35.4918 130.664 35.4782L131.389 35.3708L136.852 34.1225L139.094 33.1828L140.437 30.3773L132.584 32.5653C132.004 32.6061 131.421 32.5378 130.866 32.364C130.449 32.1089 130.288 31.5854 130.557 30.082C130.685 28.7903 131.276 27.5886 132.221 26.6993C133.04 25.8536 134.53 24.471 135.255 23.8132C135.519 23.5706 135.83 23.3835 136.168 23.2629L136.785 23.0481C137.142 22.9215 137.525 22.8846 137.899 22.9407C138.046 22.9626 138.179 23.0351 138.277 23.1455C138.375 23.2559 138.432 23.3972 138.436 23.5448L138.611 25.5046Z" fill={negative ? '#FFF' : '#000855'} />
  </svg>
);

Logo.defaultProps = {
  width: 180,
  negative: false
};

Logo.propTypes = {
  width: PropTypes.number.isRequired,
  negative: PropTypes.bool.isRequired,
};


export default Logo;
