import styled, { createGlobalStyle, css } from 'styled-components';

/**
 *
 * @type {{tablet: number, desktop: number, laptopL: number, mobileS: number, mobileM: number, laptop: number, mobileL: number}}
 */
export const responsiveDevicesSize = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 480,
  tablet: 800,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
}

/**
 *
 * @type {{tablet: string, desktop: string, laptopL: string, mobileS: string, mobileM: string, laptop: string, mobileL: string}}
 */
export const responsiveDevices = {
  mobileS: `@media screen and (max-width: ${responsiveDevicesSize.mobileS}px)`,
  mobileM: `@media screen and (max-width: ${responsiveDevicesSize.mobileM}px)`,
  mobileL: `@media screen and (max-width: ${responsiveDevicesSize.mobileL}px)`,
  tablet: `@media screen and (max-width: ${responsiveDevicesSize.tablet}px)`,
  laptop: `@media screen and (max-width: ${responsiveDevicesSize.laptop}px)`,
  laptopL: `@media screen and (max-width: ${responsiveDevicesSize.laptopL}px)`,
  desktop: `@media screen and (max-width: ${responsiveDevicesSize.desktop}px)`,
};

export const containerMixin = css`
  width: 100%;
  margin: 0 auto;
  max-width: var(--container-max-width);
  padding: var(--container-padding);
`;

export const Container = styled.div`
  ${containerMixin};
`;

export const hoverTransitionMixin = css`
  transition: all 0.3s ease-in-out;
`;

export const ellipsisMixin = css`
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  white-space: nowrap;
`;

export const Title = styled.h2`
  ${({ blank }) => !blank && css`
    font-family: var(--main-typeface);
    font-size: 5.4rem;
    line-height: 6.3rem;
  
    letter-spacing: 0.01em;
    ${responsiveDevices.tablet} {
      font-size: 3.6rem;
      line-height: 4.2rem;
    }
    ${({ as, alike }) => (as === 'h3' || alike === 'h3') && css`
      font-size: 3.6rem;
      line-height: 4.2rem;
      font-weight: 700;
      ${responsiveDevices.tablet} {
        font-size: 2.4rem;
        line-height: 2.8rem;
      }
    `};
    ${({ as, alike }) => (alike === 'h4' || alike === 'h5' || as === 'h4' || as === 'h5') && css`
      font-size: 2.4rem;
      line-height: 2.8rem;
      ${responsiveDevices.tablet} {
        font-size: 1.6rem;
        line-height: 1.9rem;
      }
    `};
    
    ${({ color }) => color ? css`color: var(${color});` : css`color: var(--c-blue-d);`};
    ${({ fontSize }) => fontSize && css`font-size: ${fontSize}rem;`};
    ${({ fontWeight }) => fontWeight ? css`font-weight: ${fontWeight}` : css`font-weight: 400;`};
    ${({ textAlign }) => textAlign ? css`text-align: ${textAlign}` : css`text-align: center;`};
    ${({ marginBottom }) => marginBottom ? css`margin-bottom: ${marginBottom}rem` : css`margin-bottom: 0`};
    ${({ withBorder }) => withBorder && css`
      display: flex;
      flex-direction: column;
      align-items: center;
      &:after {
        content: '';
        display: inline-flex;
        height: 0.3rem;
        width: 100%;
        max-width: 10rem;
        background-color: var(--c-blue-d);
        margin-top: 1.9rem;
      }
    `}
  `};
`;

export const Text = styled.p`
  font-family: var(--main-typeface);
  ${({ color }) => color ? css`color: var(${color});` : css`color: var(--c-black);`};
  ${({ fontSize }) => fontSize ? css`font-size: ${fontSize}rem;` : css`font-size: 1.6rem;` };
  ${({ lineHeight }) => lineHeight ? css`line-height: ${lineHeight}rem;` : css`line-height: 1.9rem;` };
  ${({ fontWeight }) => fontWeight ? css`font-weight: ${fontWeight}` : css`font-weight: 400;`};
  ${({ textAlign }) => textAlign && css`text-align: ${textAlign}`};
  ${({ as }) => as === 'a' && css`
    text-decoration: none;
    cursor: pointer;
    ${hoverTransitionMixin};
    :hover {
      text-decoration: underline;
    }
  `};
`;

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  font-family: var(--main-typeface);
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.05em;
  line-height: 12px;
  
  cursor: pointer;
  ${hoverTransitionMixin};
  
  border: none;
  border-radius: 3px;
  
  padding: 1.4rem 5rem;
  height: 44px;
  
  color: var(--color-white);
  background-color: var(--color-green);
  &:hover:not(:disabled) {
    background: var(--color-green-hover);
  }
  
  ${({ highlight }) => highlight && css`box-shadow: 0 0 24px var(--color-green-highlight);`};
`;

/**
 *
 * @type {number}
 */
export const containerMaxWidth = 163;

/**
 *
 * @type {number}
 */
export const containerPadding = 9.5;


const GlobalStyles = createGlobalStyle`
  :root {
    --container-max-width: ${containerMaxWidth}rem;
    --container-padding: 0 ${containerPadding}rem;
    --container-padding-double: 0 ${containerPadding * 2}rem;
    
    --main-typeface: 'Montserrat', sans-serif;
    
    --c-white: #ffffff;
    --c-black: #000000;
    
    --c-blue-l: #0212A6;
    --c-blue-n: #000C7B;
    --c-blue-d: #000955;
    
    --c-acc-blue: #D0EAE6;
    --c-acc-green: #3EAD70;
    --c-acc-yellow: #EBC500;
    
    --lg-white-acc-blue: linear-gradient(180deg, #FFFFFF 0%, #D0EAE6 100%);
        
    --box-shadow: 0 13px 20px -10px rgba(0, 0, 0, 0.25);
    --border-radius: .9rem;
    
    --bd-filter-blur: blur(15px);
  }
  ${responsiveDevices.tablet} {
    :root {
      --container-padding: 0 1.9rem;
      --container-padding-double: 0 2.8rem;
    }
  }
  *, *:before, *:after  {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  ::selection {
    background: var(--c-blue-d);
    color: var(--c-white);
  }
  :focus {
    outline-color: var(--c-acc-green);
  }
  html {
    position: relative;
    font-size: 62.5%;
    font-family: var(--main-typeface);
    background-color: white;
    overflow-x: hidden;
  }
  body {
    overflow-x: hidden;
  }
  ul, ol {
    list-style: none;
  }
`;

export default GlobalStyles;
