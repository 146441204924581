import React  from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ViewportProvider, ScrollProvider } from '../../../services/hooks';

import GlobalStyles from '../Styled/Styled';
import Seo from '../Seo/Seo';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';

const Main = styled.main``;

const MainLayout = ({ children, meta, mainNav }) => {
  return (
    <ScrollProvider>
      <ViewportProvider>
        <Seo data={meta} />
        <GlobalStyles/>
        <Header mainNav={mainNav} />
        <Main>{children}</Main>
        <Footer />
      </ViewportProvider>
    </ScrollProvider>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  meta: PropTypes.object.isRequired,
};

export default MainLayout;
