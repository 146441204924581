import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export const useSiteMetadata = () => {
  const { site: { siteMetadata }}  = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
          keywords
          lang
          author
          mainNav {
            label
            href
          }
        }
      }
    }
  `);
  return siteMetadata
}
