import React, { useEffect, useState } from 'react';

import { useOutsideRefClick, useWindowResize, useWindowScroll } from '../../services/hooks';
import { Container, responsiveDevicesSize } from '../Misc/Styled/Styled';

import Logo from '../Misc/Icons/Logo';

import {
  HeaderWrapper, LogoWrapper, HeaderNav, HeaderNavList, HeaderNavItem, HeaderNavLink, HamMenu,
} from './HeaderStyled';

const Header = ({ mainNav }) => {
  const { windowWidth, windowHeight } = useWindowResize();
  const { windowY } = useWindowScroll();

  const [menuOpen, setMenuOpen] = useState(false);
  const [tabIndex, setTabbingIndex] = useState('');
  const [headerNegative, setHeaderNegative] = useState(false);

  const { ref: wrapperRef }  = useOutsideRefClick(setMenuOpen);
  const handleHamMenuClick = () => setMenuOpen(!menuOpen);
  const handleHamMenuItemClick = () => setMenuOpen(false);

  useEffect(() => {
    if (responsiveDevicesSize.tablet >= windowWidth) {
      menuOpen && setTabbingIndex('');
      !menuOpen && setTabbingIndex('-1');
    } else {
      setTabbingIndex('');
    }
  }, [menuOpen, windowWidth]);

  useEffect(() => {
    setHeaderNegative(windowY > windowHeight / 2);
  }, [windowY, windowHeight])

  return (
    <HeaderWrapper ref={wrapperRef} negative={headerNegative}>
      <Container>
        <LogoWrapper onClick={handleHamMenuItemClick} aria-label={'Почетна страна'} to={'/'}>
          <Logo />
          <h1>Presija</h1>
        </LogoWrapper>

        <HamMenu clicked={handleHamMenuClick} menuOpen={menuOpen} />
        <HeaderNav menuOpen={menuOpen}>
          <HeaderNavList>
            {mainNav && mainNav.map(({ label, href }, index) => (
              <HeaderNavItem key={index}>
                <HeaderNavLink tabIndex={tabIndex} to={href} onClick={handleHamMenuItemClick}>{label}</HeaderNavLink>
              </HeaderNavItem>
            ))}
          </HeaderNavList>
        </HeaderNav>

      </Container>
    </HeaderWrapper>
  );
};

export default Header;
