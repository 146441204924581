import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// const sources = [
//   data.mobileImage.childImageSharp.fluid,
//   {
//     ...data.desktopImage.childImageSharp.fluid,
//     media: `(min-width: 768px)`,
//   },
// ]

export const useImages = () => {
  return useStaticQuery(
    graphql`
      query {
        grassFieldBg: file(relativePath: { eq: "livada-bg-presija-doo.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        chicken: file(relativePath: { eq: "koka-kokoska-presija-doo.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        basketWithEggs: file(relativePath: { eq: "korpa-jaja-presija-doo.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        telephoneNegativeIcon: file(relativePath: { eq: "icons/telefon-negativ-presija-doo.png" }) {
          childImageSharp {
            fixed(width: 45, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        telephoneIconSmall: file(relativePath: { eq: "icons/telefon-presija-doo.png" }) {
          childImageSharp {
            fixed(width: 28, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        houseIconSmall: file(relativePath: { eq: "icons/kucica-presija-doo.png" }) {
          childImageSharp {
            fixed(width: 28, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        openGraphImage: file(relativePath: { eq: "open-graph-presija-doo.png" }) {
          childImageSharp {
            fixed(width: 1200, height: 630, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        eggsOnTheTable: file(relativePath: { eq: "jaja-na-stolu-presija-doo.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        eggsInCarton: file(relativePath: { eq: "jaja-u-kartonu-presija-doo.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        grassBg: file(relativePath: { eq: "trava-bg-presija-doo.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );
};
