import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';


import { Container, hoverTransitionMixin, responsiveDevices, Text } from '../Misc/Styled/Styled';

export const HeaderWrapper = styled.header`
  position: fixed;
  z-index: 999;
  
  height: 6.3rem;
  left: 0;
  right: 0;
  
  display: flex;
  align-items: center;
  
  ${hoverTransitionMixin};
  ${({ negative }) => negative && css`
    backdrop-filter: var(--bd-filter-blur);
    background: rgba(255, 255, 255, 0.7);
    box-shadow: var(--box-shadow);
  `};
  
  ${Container} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
`;

export const LogoWrapper = styled(Link)`
  cursor: pointer;
  h1 {
    position: absolute;
    visibility: hidden;
    width: 0;
    height: 0;
  }
`;

export const HeaderNav = styled.nav`
  ${responsiveDevices.tablet} {
    display: inline-flex;
    justify-content: center;
    transform: translateY(-100%);
    position: absolute;
    left: 0; right: 0; top: 0;
    z-index: -1;
    padding: 6.3rem 0 4.2rem;
    background: var(--c-white);
    ${hoverTransitionMixin};
    box-shadow: var(--box-shadow);
    ${({ menuOpen }) => menuOpen && css`transform: translateY(0);`};
  }
`;

export const HeaderNavList = styled.ul`
  display: flex;
  justify-content: flex-end;
  ${responsiveDevices.tablet} {
    flex-direction: column;
  }
`;

export const HeaderNavLink = styled(Link)`
  white-space: nowrap;
  font-size: 1.6rem;
  color: var(--c-black);
  padding: 1.3rem;
  display: inline-flex;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  ${hoverTransitionMixin};
  :hover {
    text-decoration: underline;
  }
`;

export const HeaderNavItem = styled.li`
  text-align: center;
  display: inline-flex;
  justify-content: center;
  :not(:last-of-type) {
    margin-right: ${2.8 - 1.3}rem;
    ${responsiveDevices.tablet} {
      margin-right: 0;
    }
  }
  :last-of-type {
    ${HeaderNavLink} {
      padding-right: 0;
      ${responsiveDevices.tablet} {
        padding-right: 1.3rem;
      }
    }
  }
`;

const HamMenuStyled = styled.button`
  display: none;
  cursor: pointer;
  padding: 1.1rem;
  background: none;
  border: none;
  box-shadow: none;
  ${responsiveDevices.tablet} {
    display: inline-flex;
  }
  line {
    transform-origin: center;
    ${hoverTransitionMixin};
  }
`;

export const HamMenu = ({ clicked, menuOpen, color = '#000', width = 30 }) => (
  <HamMenuStyled aria-label={`${menuOpen ? 'Close' : 'Open'} menu`} onClick={clicked}>
    <svg width={width} height={width / 1.39}
         viewBox='0 0 50 36' fill='none' xmlns='http://www.w3.org/2000/svg'
    >
      <line
        style={{ opacity: menuOpen ? 0 : 1 }}
        y1='1' x2='50' y2='1' stroke={color} strokeWidth='2'
      />
      <line
        style={{ transform: `rotate(${ menuOpen ? 45 : 0 }deg)`}}
        y1='18' x2='50' y2='18' stroke={color} strokeWidth='2'
      />
      <line
        style={{ transform: `rotate(${ menuOpen ? -45 : 0 }deg)`}}
        y1='18' x2='50' y2='18' stroke={color} strokeWidth='2'
      />
      <line
        style={{ opacity: menuOpen ? 0 : 1 }}
        y1='35' x2='50' y2='35' stroke={color} strokeWidth='2'
      />
    </svg>
  </HamMenuStyled>
);


