import React from 'react';

import { useImages } from '../../services/Queries/ImageQueries';

import { Container, Text, Title } from '../Misc/Styled/Styled';

import { FooterWrapper, FooterRow, FooterColumn, FooterColumnHeader, FooterHeaderIcon } from './FooterStyled';

const Footer = () => {
  const { telephoneIconSmall, houseIconSmall } = useImages();

  return (
    <FooterWrapper>
      <Container>
        <FooterRow>
          <FooterColumn>
            <FooterColumnHeader>
              <FooterHeaderIcon fixed={telephoneIconSmall.childImageSharp.fixed} />
              <Title
                as={'h4'}
                fontSize={1.6}
                fontWeight={'bold'}
                color={'--c-white'}
              >
                КОНТАКТ
              </Title>
            </FooterColumnHeader>
            <Text as={'a'}
                  href={'tel:+3811334722'}
                  color={'--c-white'}>
              + 381 13 334 722
            </Text>
            <Text as={'a'}
                  href={'tel:+38162707404'}
                  color={'--c-white'}>
              + 381 62 707 404
            </Text>
            <Text as={'a'}
                  href={'mail:kancelarija@presija.rs'}
                  color={'--c-white'}>
              kancelarija@presija.rs
            </Text>
          </FooterColumn>
          <FooterColumn>
            <FooterColumnHeader>
              <Title
                as={'h4'}
                fontSize={1.6}
                fontWeight={'bold'}
                color={'--c-white'}
              >
                СЕДИШТЕ ФИРМЕ
              </Title>
              <FooterHeaderIcon fixed={houseIconSmall.childImageSharp.fixed} />
            </FooterColumnHeader>
            <Text color={'--c-white'}>
              Бориса Кидрича 68, Старчево
            </Text>
            <Text color={'--c-white'}>
              Радно време: 8 - 14ч
            </Text>
          </FooterColumn>
        </FooterRow>
        <FooterRow>
          <FooterColumn>
            <Text color={'--c-white'} textAlign={'center'}>
              Пресија Д.О.О. © {(new Date()).getFullYear()}
            </Text>
          </FooterColumn>
        </FooterRow>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
