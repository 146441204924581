import React, { useRef, useEffect, useState } from 'react';
import { debounce } from 'lodash';

const ViewportContext = React.createContext({});
const ScrollContext = React.createContext({});

/**
 * Hook that handles clicks outside of the passed ref
 *
 * @param hookSetter {Function}
 * @return {{ref: React.MutableRefObject<null>}}
 */
export const useOutsideRefClick = (hookSetter) => {
  const ref = useRef(null);
  const handleClickOutside =
    (event) => (ref.current && !ref.current.contains(event.target)) && hookSetter(false);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });
  return { ref };
}

/**
 * Viewport provider for useWindowResize hook
 *
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
export const ViewportProvider = ({ children }) => {
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' && window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(typeof window !== 'undefined' && window.innerHeight);
  const handleResize = () => {
    setWindowWidth(window && window.innerWidth);
    setWindowHeight(window && window.innerHeight);
  };

  let passiveIfSupported = false;

  try {
    window.addEventListener("test", null,
      Object.defineProperty(
        {},
        "passive",
        {
          get: function() { passiveIfSupported = { passive: false }; }
        }
      )
    );
  } catch(err) {}

  useEffect(() => {
    typeof window !== 'undefined' && window.addEventListener('resize', debounce(handleResize, 200), true);
    return () => typeof window !== 'undefined' && window.removeEventListener('resize', handleResize, true);
  });
  return <ViewportContext.Provider value={{ windowWidth, windowHeight }}>{children}</ViewportContext.Provider>;
}

/**
 * Hook that handles window resize event
 *
 * @return {{windowWidth, windowHeight}}
 */
export const useWindowResize = () => {
  const { windowWidth, windowHeight } = React.useContext(ViewportContext);
  return { windowWidth, windowHeight };
}

/**
 * Viewport provider for useWindowScroll hook
 *
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
export const ScrollProvider = ({ children }) => {
  const [windowY, setWindowY] = useState(typeof window !== 'undefined' && window.scrollY);
  const [windowX, setWindowX] = useState(typeof window !== 'undefined' && window.scrollX);

  let passiveIfSupported = false;

  try {
    window.addEventListener("test", null,
      Object.defineProperty(
        {},
        "passive",
        {
          get: function() { passiveIfSupported = { passive: false }; }
        }
      )
    );
  } catch(err) {}

  const handleScroll = () => {
    setWindowY(window && window.scrollY);
    setWindowX(window && window.scrollX);
  };
  useEffect(() => {
    typeof window !== 'undefined' && window.addEventListener('scroll',  debounce(handleScroll, 200), passiveIfSupported);
    return () => typeof window !== 'undefined' && window.removeEventListener('scroll', handleScroll, passiveIfSupported);
  });
  return <ScrollContext.Provider value={{ windowY, windowX }}>{children}</ScrollContext.Provider>;
}

/**
 * Hook that handles window scroll event
 *
 * @return {{windowY, windowX}}
 */
export const useWindowScroll = () => {
  const { windowY, windowX } = React.useContext(ScrollContext);
  return { windowY, windowX };
}
