import styled, { css } from 'styled-components';
import Image from 'gatsby-image';

import { Container, responsiveDevices, Text, Title } from '../Misc/Styled/Styled';

export const FooterRow = styled.div`
  width: 100%;
  display: flex;
  ${responsiveDevices.mobileL} {
    flex-direction: column;
  }
`;

export const FooterColumn = styled.section`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  ${Text} {
    margin-bottom: 0.6rem;
  }
  ${responsiveDevices.mobileL} {
    margin-bottom: 1.9rem;
  }
`;

export const FooterColumnHeader = styled.header`
  margin-bottom: 1.9rem;
  display: flex;
  align-items: center;
`;

export const FooterHeaderIcon = styled(Image)`
  width: 100%;
  user-select: none;
  display: inline-flex;
`;

export const FooterWrapper = styled.footer`
  background-color: var(--c-blue-d);
  padding: 2.8rem 0;
  ${FooterRow} {
    :nth-of-type(1) {
      margin-bottom: 9.5rem;
      ${responsiveDevices.mobileL} {
        margin-bottom: 4.2rem;
      }
      ${FooterColumn} {
        :first-of-type {
          align-items: flex-start;
          ${FooterColumnHeader} {
            ${Title} {
              margin-left: 1.3rem;
            }
          }
          ${Text} {
            ${responsiveDevices.mobileL} {
              line-height: 2.8rem;
            }
          }
        }
        :last-of-type {
          ${FooterColumnHeader} {
            justify-content: flex-end;
            ${Title} {
              margin-right: 1.3rem;
            }
            ${responsiveDevices.mobileL} {
              flex-direction: row-reverse;
              ${Title} {
                margin-right: 0;
                margin-left: 1.3rem;
              }
          
            }
          }
          ${Text} {
            text-align: right;        
            ${responsiveDevices.mobileL} {  
              text-align: left;
            }
          }
        }
      }
    }
    :nth-of-type(2) {
      ${Text} {
        text-align: center;
        ${responsiveDevices.mobileL} {
          text-align: left;
        }
      }
    }
  }
  ${Container} {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
`;


